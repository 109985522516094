import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';

import { dictionaryService, IDictionary } from "@/app/apiServices/dictionary/public-api";
import { IdCardValid } from '@/core/utils/IdCard/public-api';
import { ISelectDto } from '@/app/apiServices/baseDto/selectDto';
import { getZSChildBaseInfoPageDto, SaveChildBaseInfo, SaveChildBaseInfoResult, ZSChildBaseInfoPageSaveDto, zsChildBaseInfoService } from '@/app/apiServices/zsChildBaseInfo/public-api';
import { thisCity_stepsList } from "@/core/utils/enum/thisCity"
import { filter, filterSelect, getStepsIcon, isNullOrEmpty } from '@/core/utils/index'
import { GradeEnum } from '@/core/utils/enum/grade';
import date from '@/core/utils/date';
import storage from "@/core/utils/storage";
import { hasRegister, houeseInconformityMessage, householdInconformityMessage } from '@/config/message';

@Component
export default class childInformationTS extends Vue {
    formModel = new SaveChildBaseInfo();
    formRule = {
        gradeCode: [
            { required: true, message: '请选择幼儿报名年级', trigger: 'blur' },
        ],
        regNumber: [
            { required: true, message: '请输入幼儿市登记编号', trigger: 'blur' },
            { type: 'string', min: 10, max: 10, message: '请填写上海市适龄幼儿入园信息登记表上的十位数登记编号！', trigger: 'blur' },
        ],
        gB_SFZ: [
            { required: true, message: '请输入幼儿证件(居民身份证 证件号码)', trigger: 'blur' },
            { type: 'string', min: 18, max: 18, message: '请输入18位幼儿证件号码！', trigger: 'blur' },
            {
                validator: (rule: any, value: string, callback: any) => {
                    const gbSFZValidator = IdCardValid(value);
                    if (gbSFZValidator === true) {
                        callback();
                    } else {
                        callback(new Error(`${gbSFZValidator}`));
                    }
                },
                trigger: 'blur'
            }
        ],
        childName: [
            { required: true, message: '请输入幼儿姓名', trigger: 'blur' },
        ],
        sex: [
            { required: true, message: '请选择幼儿性别', trigger: 'blur' },
        ],
        birthday: [
            { type: 'date', required: true, message: '请选择幼儿出生日期', trigger: 'blur' },
        ],
        householdImmigrationDate: [
            { type: 'date', required: true, message: '请选择幼儿户籍迁入本市/招生范围日期', trigger: 'blur' },
        ],
        // immigrationInfo: [
        //     { required: true, message: '请输入何时由何地牵来本址', trigger: 'blur' },
        // ],
        isHQ: [
            { required: true, message: '请选择是否华侨', trigger: 'blur' },
        ],
        // isOnlyChild: [
        //     { required: true, message: '请选择幼儿是否为独生子女', trigger: 'blur' },
        // ],
        // healthStatus: [
        //     { required: true, message: '请选择身体健康', trigger: 'blur' },
        // ],
        // healthRemark: [
        //     { required: true, message: '请输入其他', trigger: 'blur' },
        // ],
        gB_MZ_Code: [
            { required: true, message: '请选择幼儿民族', trigger: 'blur' },
        ],
        isTwins: [
            { required: true, message: '请选择是否双胞胎/多胞胎', trigger: 'blur' },
        ],
        cbReasonCode: [
            { required: true, message: '请选择插班原因', trigger: 'blur' },
        ],
        enterGardenType: [
            { type: 'number', required: true, message: '请选择入园方式', trigger: 'blur' },
            {
                validator: (rule: any, value: number, callback: any) => {
                    if (value == 1 || value == 2) {
                        callback();
                    } else {
                        callback(new Error('请选择入园方式'));
                    }
                },
                trigger: 'blur'
            },
        ],
        nativeTypeCode: [
            { required: true, message: '请选择幼儿户籍类别', trigger: 'blur' },
        ],
        guarderName: [
            { required: true, message: '请输入主要联系的监护人姓名', trigger: 'blur' },
        ],
        guarderRelation_Code: [
            { required: true, message: '请选择监护人与幼儿关系', trigger: 'blur' },
        ],
        guarderZJLX: [
            { required: true, message: '请选择监护人证件类型', trigger: 'blur' },
        ],
        guarderZJH: [
            { required: true, message: '请输入监护人证件号码', trigger: 'blur' },
            { type: 'string', min: 18, max: 18, message: '请输入18位监护人证件号码！', trigger: 'blur' },
            {
                validator: (rule: any, value: string, callback: any) => {
                    const gbSFZValidator = IdCardValid(value);
                    if (gbSFZValidator === true) {
                        callback();
                    } else {
                        callback(new Error(`${gbSFZValidator}`));
                    }
                },
                trigger: 'blur'
            }
        ],
        guarderPhone: [
            { required: true, message: '请输入监护人联系电话', trigger: 'blur' },
        ],
        relationOfDomicile: [{ required: true, message: '请选择户主与幼儿关系', trigger: 'blur' },],
        relationOfHouseMasterCode: [
            { required: true, message: '请选择产权人与幼儿关系', trigger: 'blur' },
        ],
        estateTime: [
            { required: true, message: '请选择房产证办妥时间', trigger: 'blur' },
        ],
        permanentDistrictCode: [
            { required: true, message: '请选择区', trigger: 'blur' },
        ],
        permanentStreetCode: [
            { required: true, message: '请选择街道', trigger: 'blur' },
        ],
        juWeiCode: [
            { required: true, message: '请选择居委', trigger: 'blur' },
        ],
        houseStreetCode: [
            { required: true, message: '请选择街道', trigger: 'blur' },
        ],
        houseJuWeiCode: [
            { required: true, message: '请选择居委', trigger: 'blur' },
        ],
        houseAddress: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        houseOwnerIdCard: [
            { required: true, message: '请输入房产持证人身份证号', trigger: 'blur' },
            { type: 'string', min: 18, max: 18, message: '请输入18位房产持证人身份证号！', trigger: 'blur' },
            {
                validator: (rule: any, value: string, callback: any) => {
                    const gbSFZValidator = IdCardValid(value);
                    if (gbSFZValidator === true) {
                        callback();
                    } else {
                        callback(new Error(`${gbSFZValidator}`));
                    }
                },
                trigger: 'blur'
            }
        ],
        houseCertCode: [
            { required: true, message: '请输入房产证条形码编号', trigger: 'blur' },
            { type: 'string', max: 12, message: '房产证条形码编号不得超过12位', trigger: 'blur' },
        ],
        address: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        homeDistrictCode: [
            { required: true, message: '请选择区', trigger: 'blur' },
        ],
        homeStreetCode: [
            { required: true, message: '请选择街道', trigger: 'blur' },
        ],
        nowJuWeiCode: [
            { required: true, message: '请选择居委', trigger: 'blur' },
        ],
        postalCode: [
            { required: true, message: '请输入邮政编码', trigger: 'blur' },
            { type: 'string', min: 6, max: 6, message: '请输入6位邮政编码', trigger: 'blur' },
            {
                validator: (rule: any, value: string, callback: any) => {
                    if (/^[0-9][0-9]{5}$/.test(value)) {
                        callback();
                    } else {
                        callback(new Error('邮政编码格式不正确'));
                    }
                },
                trigger: 'blur'
            }
        ],
        nowAddress: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
        ]
    };
    //年级
    gradeName = "";
    //证件类型
    zjlxListDic: IDictionary[] = [];
    //名族
    mzListDic: IDictionary[] = [];
    //插班原因
    cbReasonDic: ISelectDto[] = [];
    //入园方式
    enterGardenTypeDic: ISelectDto[] = [];
    //产权人与幼儿关系
    relationListDic: IDictionary[] = [];
    // 房产证办妥时间
    estateTimeSelect: ISelectDto[] = [];
    //户主与幼儿关系
    relationOfDomicileSelect: ISelectDto[] = [];
    //幼儿户籍类别
    nativeTypeSelect: ISelectDto[] = [];
    juWeiListDic: IDictionary[] = [];
    //房产
    fangchanJieDao: IDictionary[] = [];
    fangchanJuWei: IDictionary[] = [];
    // 户籍
    huJiJieDao: IDictionary[] = [];
    huJiJuWei: IDictionary[] = [];
    // 通信
    tongXinJieDao: IDictionary[] = [];
    tongXinJuWei: IDictionary[] = [];

    stepsList = thisCity_stepsList;
    activeSteps = 0;

    getStepsIcon(index: number) {
        return getStepsIcon(index, this.activeSteps);
    }

    //是否显示户主与幼儿关系
    isShowRelationOfDomicile() {
        if (this.formModel.gradeCode === 'SZNJ_xb' &&
            this.formModel.householdImmigrationDate != null) {
            const timeString = `${new Date().getFullYear()}-01-01`;
            const time = new Date(timeString).getTime() - (8 * 3600 * 1000);   //手动减8小时
            return new Date(this.formModel.householdImmigrationDate).getTime() >= time;
        }
        return false;
    }

    // select change

    //插班原因
    cbReasonChange(val: string) {
        switch (val) {
            case "swry":
            case "hhjszdry":
                this.formModel.enterGardenType = 1;
                //户籍所在地入园，幼儿户籍类别默认常住
                if (isNullOrEmpty(this.formModel.nativeTypeCode)) {
                    this.formModel.nativeTypeCode = "HJLB_cz";
                }
                break;
            case "jjqr":
                this.formModel.enterGardenType = 2;
                break;
            default:
                break;
        }
        this.enterGardenTypeChange(this.formModel.enterGardenType);
    }

    // 入园方式
    enterGardenTypeChange(val: number) {
        if (val == 1) {
            // 加载 户籍 街道dic
            this.formModel.permanentDistrictCode = "huangpu";

            // 加载 户籍 区dic
            this.permanentDistrictCodeChange(this.formModel.permanentDistrictCode);
        }
        else if (val == 2) {
            //加载 房产街道
            this.formModel.houseCountyCode = "huangpu";

            // 加载 房产 区dic
            this.houseCountyCodeChange(this.formModel.houseCountyCode);
        }
        this.formModel.enterGardenType = val;
    }

    //房产  区、街道
    houseCountyCodeChange(val: string) {
        const isFind = this.juWeiListDic.find(a => a.code === val);
        if (isFind) {
            this.fangchanJieDao = isFind.children;
        } else {
            this.fangchanJieDao = [];
        }
    }

    houseStreetCodeChange(val: string, isManual?: boolean) {
        const isFind = this.fangchanJieDao.find(a => a.code === val);
        if (isFind) {
            this.fangchanJuWei = isFind.children;
        } else {
            this.fangchanJuWei = [];
        }

        if (isManual === undefined) {
            this.formModel.houseJuWeiCode = "";
        }
    }

    // 户籍 区、街道
    permanentDistrictCodeChange(val: string, isManual?: boolean) {
        const isFind = this.juWeiListDic.find(a => a.code === val);
        if (isFind) {
            this.huJiJieDao = isFind.children;
        } else {
            this.huJiJieDao = [];
        }
        if (isManual === undefined) {
            this.formModel.permanentStreetCode = "";
            this.formModel.juWeiCode = "";
        }
    }
    permanentStreetCodeChange(val: string, isManual?: boolean) {
        const isFind = this.huJiJieDao.find(a => a.code === val);
        if (isFind) {
            this.huJiJuWei = isFind.children;
        } else {
            this.huJiJuWei = [];
        }
        if (isManual === undefined) {
            this.formModel.juWeiCode = "";
        }
    }

    // 通信 区、街道
    homeDistrictCodeChange(val: string, isManual?: boolean) {
        const isFind = this.juWeiListDic.find(a => a.code === val);
        if (isFind) {
            this.tongXinJieDao = isFind.children;
        } else {
            this.tongXinJieDao = [];
        }

        if (isManual === undefined) {
            this.formModel.homeStreetCode = "";
            this.formModel.nowJuWeiCode = "";
        }
    }
    homeStreetCodeChange(val: string, isManual?: boolean) {
        const isFind = this.tongXinJieDao.find(a => a.code === val);
        if (isFind) {
            this.tongXinJuWei = isFind.children;
        } else {
            this.tongXinJuWei = [];
        }
        if (isManual === undefined) {
            this.formModel.nowJuWeiCode = "";
        }
    }

    //产权人是否是幼儿或父母
    relationOfHouseMasterCodeChange(val: string) {
        if (val === '否') {
            this.alertDialog(houeseInconformityMessage);
        }
    }

    //户主与幼儿关系
    relationOfDomicileChange(val: string) {
        if (val === '其他') {
            this.alertDialog(householdInconformityMessage);
        }
    }

    //房产证办妥时间
    estateTimeChange(val: string) {
        //房产证办妥时间，选择“2022年1月1日及之后”
        if (val == this.estateTimeSelect[1].optionValue && this.formModel.gradeCode == "SZNJ_xb") {
            this.$dialog.alert({
                title: "提示",
                message: `根据招生政策,您幼儿的房产证办妥时间在${val},将根据招生政策和资源情况在全区范围内统筹安排。`
            });
        }
    }

    // select change end

    // 点击确定后返回幼儿报名记录页
    alertDialog(message: string) {
        this.$dialog.alert({
            title: "提示",
            message: message
        })
            .then(() => {
                this.$router.push("/record");
            });
    }

    // 同户籍地址按钮点击
    tongHuJiClick() {
        this.formModel.homeDistrictCode = this.formModel.permanentDistrictCode;
        this.formModel.homeStreetCode = this.formModel.permanentStreetCode;
        this.formModel.nowJuWeiCode = this.formModel.juWeiCode;
        this.formModel.nowAddress = this.formModel.address;

        // 通信 居委 三级 赋值
        this.homeDistrictCodeChange(this.formModel.homeDistrictCode, true);
        this.homeStreetCodeChange(this.formModel.homeStreetCode, true);
    }

    // 同房产地址按钮点击
    tongHouseClick() {
        this.formModel.homeDistrictCode = "huangpu";
        this.formModel.homeStreetCode = this.formModel.houseStreetCode;
        this.formModel.nowJuWeiCode = this.formModel.houseJuWeiCode;
        this.formModel.nowAddress = this.formModel.houseAddress;

        // 通信 居委 三级 赋值
        this.homeDistrictCodeChange(this.formModel.homeDistrictCode, true);
        this.homeStreetCodeChange(this.formModel.homeStreetCode, true);
    }

    // 根据code 获得 name
    formModelCodeToName() {
        this.formModel.gB_MZ = filter(this.mzListDic, this.formModel.gB_MZ_Code);

        // 户籍信息 加载 区 街道 居委
        this.formModel.permanentDistrict = filter(this.juWeiListDic, this.formModel.permanentDistrictCode);
        this.formModel.permanentStreet = filter(this.huJiJieDao, this.formModel.permanentStreetCode);
        this.formModel.juWei = filter(this.huJiJuWei, this.formModel.juWeiCode);

        // 通信地址 区 街道 居委
        this.formModel.homeDistrict = filter(this.juWeiListDic, this.formModel.homeDistrictCode);
        this.formModel.homeStreet = filter(this.tongXinJieDao, this.formModel.homeStreetCode);
        this.formModel.nowJuWei = filter(this.tongXinJuWei, this.formModel.nowJuWeiCode);

        // 房产信息  区 街道 居委
        this.formModel.houseCounty = filter(this.juWeiListDic, this.formModel.houseCountyCode);
        this.formModel.houseStreet = filter(this.fangchanJieDao, this.formModel.houseStreetCode);
        this.formModel.houseJuWei = filter(this.fangchanJuWei, this.formModel.houseJuWeiCode);

        //插班原因
        this.formModel.cbReason = filterSelect(this.cbReasonDic, this.formModel.cbReasonCode);

        //幼儿户籍类别
        this.formModel.nativeType = filterSelect(this.nativeTypeSelect, this.formModel.nativeTypeCode);

        //产权人是否为幼儿或父母
        this.formModel.relationOfHouseMaster = this.formModel.relationOfHouseMasterCode;
    }

    // 初始化 formModel
    initializationFormModel() {
        const { birthday, cardNumber, cardType, cardTypeName, gradeCode, regNumber, childType }: any = storage.GET_RegistrationInfo();
        this.gradeName = filter(GradeEnum, gradeCode);

        this.formModel.gradeCode = gradeCode;
        this.formModel.regNumber = regNumber;
        this.formModel.gB_ZJLX = cardTypeName;
        this.formModel.gB_ZJLX_Code = cardType;
        this.formModel.gB_SFZ = cardNumber;
        this.formModel.birthday = birthday;
        this.formModel.type = Number(childType);
        this.formModel.setSexByGB_SFZ();
        
        //设置性别
        if (cardType == 'ZJLX_sfz' && isNullOrEmpty(this.formModel.sex)) {
            this.formModel.setSexByGB_SFZ();
        }

        // //托班默认户籍地入园
        // if (this.formModel.gradeCode == "SZNJ_tb") {
        //     this.formModel.enterGardenType = 1;
        // }

        //户籍所在区 区
        if (this.formModel.enterGardenType == 1) {
            //户籍入园默认黄浦区
            this.formModel.permanentDistrictCode = "huangpu";
        }
        else {
            //其他方式入园 无数据的情况下默认黄浦区，否则以实际填报为准
            this.formModel.permanentDistrictCode = this.formModel.permanentDistrictCode ? this.formModel.permanentDistrictCode : "huangpu";
        }

        // //民族 默认汉族
        // this.formModel.gB_MZ_Code = this.formModel.gB_MZ_Code == null ? 'mz_01' : this.formModel.gB_MZ_Code;
        // //是否是华侨 默认否
        // this.formModel.isHQ = this.formModel.isHQ == null ? '否' : this.formModel.isHQ;
    }

    // 加载表单数据
    viewLoad(): void {
        const { childName, birthday, cardNumber, cardType, gradeCode, childType }: any = storage.GET_RegistrationInfo();
        const params = {
            childName: childName,
            birthday: birthday,
            cardNumber: cardNumber,
            cardType: cardType,
            gradeCode: gradeCode,
            childType: childType
        }
        zsChildBaseInfoService.getZSChildBaseInfoPage(params)
            .then((res: getZSChildBaseInfoPageDto) => {
                // 可能为 null 不存在数据
                if (res) {
                    if (res.verifyResult?.success == false) {
                        this.$toast({
                            message: res.verifyResult?.message,
                            position: 'top'
                        });
                        return;
                    }
                    this.formModel = Object.assign(this.formModel, res);

                    this.cbReasonDic = res.cbReasonSelect || [];
                    this.enterGardenTypeDic = res.enterTypeSelect || [];
                    this.nativeTypeSelect = res.nativeTypeSelect || [];
                    this.estateTimeSelect = res.estateTimeSelect || [];
                    this.relationOfDomicileSelect = res.relationOfDomicileSelect || [];
                }
                this.initializationFormModel();

                //加载房产
                this.houseCountyCodeChange("huangpu");
                this.houseStreetCodeChange(this.formModel.houseStreetCode, true);

                // 加载 户籍 区dic
                this.permanentDistrictCodeChange(this.formModel.permanentDistrictCode, true);
                // 加载 户籍 街道dic
                this.permanentStreetCodeChange(this.formModel.permanentStreetCode, true);

                // 加载 通信 区dic
                this.homeDistrictCodeChange(this.formModel.homeDistrictCode, true);
                // 加载 通信 街道dic
                this.homeStreetCodeChange(this.formModel.homeStreetCode, true);
            });
    }

    // 下一步点击
    nextOnClick(): void {

        //为了过表单data数据验证
        this.formModel.birthday = new Date(date.formatD((<Date>this.formModel.birthday).toString()));

        //幼儿户籍迁入本市/招生范围日期（处理8小时时差问题）
        if (this.formModel.householdImmigrationDate !== null) {
            this.formModel.householdImmigrationDate = new Date(date.formatD((<Date>this.formModel.householdImmigrationDate).toString()));
        }

        const { formModel }: any = this.$refs;
        formModel.validate((valid: boolean) => {
            if (valid) {
                this.formModelCodeToName();
                // 确定下一步
                const nextFun = () => {
                    const input = Object.assign(new ZSChildBaseInfoPageSaveDto(), this.formModel);
                    zsChildBaseInfoService.saveZSChildBaseInfoPage(input)
                        .then((res: SaveChildBaseInfoResult) => {
                            if (res.verifyResult.success === true) {
                                this.formModel.id = res.data;
                                this.$store.dispatch('ACTION_ChildBaseInfo', this.formModel);
                                // this.$router.push(`/thisCity/familyMemberInformation/${res.data}/${this.formModel.enterGardenType}/${this.formModel.gradeCode}`);

                                //更新部分登记信息
                                const registrationInfo = storage.GET_RegistrationInfo();
                                registrationInfo.childId = this.formModel.id;
                                registrationInfo.enterGardenType = this.formModel.enterGardenType;
                                this.$store.dispatch('ACTION_RegistrationInfo', registrationInfo);

                                this.$router.push(`/thisCity/familyMemberInformation`);
                            }
                            else {
                                if (res.verifyResult.exceptionCode == "GOHOME") {
                                    this.$dialog.alert({
                                        message: hasRegister,
                                    }).then(() => {
                                        this.$router.push("/record");
                                    });
                                }
                                else {
                                    this.$toast({
                                        message: res.verifyResult.message,
                                        position: 'top'
                                    });
                                }
                            }
                        })
                }

                nextFun();
            }
            else {
                this.$toast({
                    message: '填写内容存在错误请仔细核对！',
                    position: 'top'
                });
            }
        });
    }
    // 加载字典
    async viewLoadDicArray() {
        this.zjlxListDic = await dictionaryService.getZjlxList();
        this.mzListDic = await dictionaryService.getMZList();
        // this.relationListDic = await dictionaryService.getRelationList();
        this.juWeiListDic = await dictionaryService.getJuweiList('');
        this.viewLoad();
    }

    mounted(): void {
        this.viewLoadDicArray();
    }
}